@charset "utf-8";
/* CSS Document */

.clear{
	clear:both;
	width:0;
	font-size:0px;
}

/* 角丸　*/
img.kakumaru_01{
	border-radius: 10px;        /* CSS3草案 */  
	-webkit-border-radius: 10px;    /* Safari,Google Chrome用 */  
	-moz-border-radius: 10px;   /* Firefox用 */ 
}

/* 文字色 */
span.red,p.red,h3.red{color:#e60012!important;}
span.blue,p.blue,h3.blue,a.blue{color:#005bac!important;}
span.green,p.green,h3.green{color:#060!important;}
span.yellow,p.yellow,h3.yellow{color:#990!important;}
span.pink,p.pink,h3.pink{color:#ff0084!important;}

/* 文字サイズ */
span.t_10{
	font-size:10px;
}
span.t_11{
	font-size:11px;
}
span.t_14{
	font-size:14px;
}
span.t_18{
	font-size:18px;
}
span.t_30{
	font-size:30px;
}

span.bold,
ul.bold{
 font-weight:bold;
}



/* h_1 */
#left h3{
	padding:30px 0 20px 0;
}
h2 img,
h3 img{
	padding:0;
}

/* ---- pプロパティ ------- */
p{
	padding:0 0 10px 0;
}
p.p_01{
	padding:0 0 0 20px!important;
}
p.under_space{
	padding:0 20px 40px 20px!important;
}
p.bold{
	font-weight:bold;
}



b{
	padding:0 0 10px 20px;
}


ul.ul_02{
	padding:0 0 20px 40px;
}

#left img{
	padding:0 0 10px 0;
}


/* div */
.div_01{
	padding:0 0 10px 0;
}

.div_01 ul{
	padding:0 0 0 20px;
}
.div_01 dl{
	padding:0 0 0 20px;
}
p.p_no_04{/* ※_   */
	text-indent:-15px;
	padding:0 0 0 15px!important;
}





/* table */
/*01_基本表　1ｐｘの線*/
table.table_01{
	border-top:#555555 1px solid;
	border-left:#555555 1px solid;
	margin:0;
}
.table_01 th{
	border-right:#555555 1px solid!important;
	border-bottom:#555555 1px solid;
	background:#eee;
	vertical-align:top;
	text-align:left;
	padding:5px 5px 5px 5px;
}
.table_01 td{
	border-right:#555555 1px solid!important;
	border-bottom:#555555 1px solid!important;
	vertical-align:top;
	padding:5px 5px 5px 5px;
}

.table_01 th.th_center{
	text-align:center!important;
	vertical-align:middle!important;
}

.table_01 .bg_blue td{
	background:#ececff;
}
.table_01 .bg_red td{
	background:#FFF7F7;
}



/*02_空白をつかい要素を上下とあわせる*/
/* 例）　　　 tel  :   0000-00-000   */
table.table_02{
	margin:0 0 40px 20px;
}
.table_02 th{
	text-align:left;
	vertical-align:top;
	padding:0 10px 10px 0;

}
.table_02 td{
	text-align:left!important;
	vertical-align:top;
	padding:0 20px 10px 0;
}


/* ul */
.li_left li{
	float:left;
	padding:0;
}
.li_dotted li{
	text-indent:-8px;
	padding:0 0 0 8px;
}
.li_dotted li{
	text-indent:-8px;
	padding:0 0 0 8px;
}
.li_no_01 li{/* 1.    */
	text-indent:-8px;
	padding:0 0 0 8px;
}
.li_no_02 li{/* (1)_  */
	text-indent:-21px;
	padding:0 0 0 21px;
}
.li_no_03 li{/* ①_   */
	text-indent:-18px;
	padding:0 0 0 18px;
}
.li_no_04 li{/* ※_   */
	text-indent:-15px;
	padding:0 0 0 15px;
}
.li_last{
	padding:0!important;
}

/* list */
.list_2 li{ width:49%; margin:0 0 20px 0;}
.list_2 li:nth-child(odd){ float:left;}
.list_2 li:nth-child(even){ float:right;}

.list_3 li{ width:32%; margin:0 0 10px 0;}
.list_3 li:nth-child(1n){ float:left; padding:0 0px 0 0;}
.list_3 li:nth-child(2n){ float:left;}
.list_3 li:nth-child(3n){ float:right;}





/* float */
p.p_left,
b.b_left,
img.img_left,
ul.ul_left,
.li_left li,
dl.dl_left,
dt.dt_left,
dd.dd_left,
table.table_left,
div.div_left{
	float:left!important;
}
p.p_right,
b.b_right,
img.img_right,
ul.ul_right,
.li_right li,
dl.dl_right,
dt.dt_right,
dd.dd_right,
table.table_right,
div.div_right{
	float:right!important;
}

